import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { duplicateRenamePage } from "../../Store/Slices/userHtmlSlice";
import "../Builder_prompt_components/global_theme/fonts.css";
const AddDuplicatePage = ({
  duplicatePage,
  isAddingDupPage,
  setIsAddingDupPage,
}) => {
  const dispatch = useDispatch();

  const [pageName, setPageName] = useState("");
  function formatPageName(pageName) {
    return pageName
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace one or more spaces with a single dash
      .replace(/^[a-z]/, (char) => char.toUpperCase())
      .replace(/-+/g, "-") // Replace multiple dashes with a single dash
      .replace(/^-+|-+$/g, "") // Remove leading or trailing dashes
      .trim(); // Capitalize the first letter
  }

  const handleInputChange = (event) => {
    setPageName(event.target.value);
  };

  const handleAddPage = () => {
    if (pageName.trim() !== "") {
      setTimeout(() => {
        dispatch(
          duplicateRenamePage({
            pageName: duplicatePage,
            newName: formatPageName(pageName),
          })
        );
      }, 100);

      setPageName("");
      setIsAddingDupPage(false); // Hide the input field and buttons after adding the page
    }
  };

  const handleCancel = () => {
    setPageName("");
    setIsAddingDupPage(false); // Hide the input field and buttons without adding the page
  };
  return (
    <div className="">
      {!isAddingDupPage ? (
        <div className="text-[#a3a3a3] hover:text-gray-900">
          <button onClick={() => setIsAddingDupPage(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              class="lucide lucide-copy"
            >
              <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
              <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
            </svg>
          </button>
        </div>
      ) : (
        <div className="p-2 flex flex-col gap-3 justify-between items-center w-full">
          <input
            type="text"
            className="border font_inter w-full border-zinc-100 px-4 py-2 rounded-md bg-[#F4f4f4] placeholder:text-zinc-400 text-zinc-600"
            placeholder="Enter Page Name"
            value={pageName}
            onChange={handleInputChange}
          />
          <div className="w-full font_inter">
            <button
              className="w-[48%] px-4 py-2 rounded-md mr-2 bg-[#FFD488] text-black hover:bg-green-600"
              onClick={handleAddPage}
            >
              Add
            </button>
            <button
              className="w-[48%] px-4 py-2 rounded-md bg-[#f4f4f4] text-black hover:bg-gray-200"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
          <p className="text-xs text-zinc-600 mb-2">
            Enter new name for the page. The Page Name will be modified to
            become url e.g. "Privacy Page" will have url as "/privacy-page"
          </p>
        </div>
      )}
    </div>
  );
};

export default AddDuplicatePage;
