import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import supabase from "../../config/supabse";
import "../SignIn/spinner.css";
import pageHtmlforSaving from "../Saving/SavingHtml";
import { initalizeUserSlice } from "../../Store/Slices/userHtmlSlice";

function Publish({ alertMessage, setAlertMessage, userId }) {
  const { credId } = useParams();
  const confirmId = userId;
  const [isPublishing, setIsPublishing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  const Idea = useSelector((state) => {
    return state.Idea.data;
  });
  const Name = useSelector((state) => {
    return state.Name.data;
  });
  const subdomainId = useSelector((state) => {
    return state.Name.subdomain;
  });
  const Color = useSelector((state) => {
    return state.Color.data;
  });
  const Hue = useSelector((state) => {
    return state.Color.hue;
  });
  const siteId = useSelector((state) => {
    return state.Color.siteId;
  });
  const Fonts = useSelector((state) => {
    return state.Color.fonts;
  });
  const Section = useSelector((state) => {
    return state.Section.data;
  });
  const Data = useSelector((state) => state.userHtml.data);
  const Pages = useSelector((state) => state.userHtml.pages);
  const index = useSelector((state) => state.userHtml.index);
  const isSiteCreated = useSelector((state) => state.userHtml.isSiteCreated);
  const Queries = useSelector((state) => state.Query.data);
  const Images = useSelector((state) => state.Query.images);
  const ImagesUsed = useSelector((state) => state.Query.used);

  async function saveData() {
    setIsSaving(true);
    dispatcher();
    if (credId !== confirmId) {
      setAlertMessage((prev) => ({
        ...prev,
        display: "flex",
        messageType: "ERROR",
        message:
          "Your data cannot be saved try again later! Go back to dashbard and come again ",
      }));
      return;
    }

    const { data: mostRecentRow, error: fetchError } = await supabase
      .from("histories")
      .select("*")
      .eq("domain", subdomainId)
      .eq("deployedVersion", true)
      .order("created_at", { ascending: false })
      .limit(1);

    if (fetchError) {
      setAlertMessage((prev) => ({
        ...prev,
        display: "flex",
        messageType: "ERROR",
        message: "Your data cannot be saved try again later! ",
      }));
      console.error("Error fetching the most recent row:", fetchError);
    } else if (mostRecentRow.length > 0) {
      setAlertMessage((prev) => ({
        ...prev,
        display: "flex",
        messageType: "SUCCESS",
        message: "Your Website is Saving.... Step 1/3 ",
      }));
      if (!mostRecentRow[0].userData.isSiteCreated) {
        updateIsSiteCreated(credId);
      }
      const { error: upsertError } = await supabase.from("histories").upsert({
        ...mostRecentRow[0],
        deployedVersion: false,
      });

      setAlertMessage((prev) => ({
        ...prev,
        display: "flex",
        messageType: "SUCCESS",
        message: "Your Website is Saving.... Step 2/3 ",
      }));
      if (upsertError) {
        setAlertMessage((prev) => ({
          ...prev,
          display: "flex",
          messageType: "ERROR",
          message: "Your data cannot be saved try again later! ",
        }));

        console.error("Error upserting deployedVersion:", upsertError);
      }

      const { data, error } = await supabase.from("histories").insert({
        webIdea: Idea,
        webName: Name,
        webColor: Color,
        userData: {
          data: Data,
          pages: Pages,
          index: index,
          isSiteCreated: isSiteCreated,
        },
        userAssets: {
          images: Images,
          queries: Queries,
          used: ImagesUsed,
        },
        userId: credId,
        webSectionType: Section,
        webTheme: {
          hue: Hue,
          fonts: Fonts,
        },
        siteId: siteId,
        domain: subdomainId,
        deployedVersion: true, // Set deployedVersion to true for the new row
      });

      if (error) {
        const { error: upsertErrorRollback } = await supabase
          .from("histories")
          .upsert({
            ...mostRecentRow[0],
            deployedVersion: true,
          });

        if (upsertErrorRollback) {
          setAlertMessage((prev) => ({
            ...prev,
            display: "flex",
            messageType: "ERROR",
            message:
              "Your data cannot be saved! Please go back to dashboard & open editor again. ",
          }));
        } else {
          setAlertMessage((prev) => ({
            ...prev,
            display: "flex",
            messageType: "ERROR",
            message: "Your data cannot be saved try again later! ",
          }));
        }

        console.error("Error inserting data:", error);
        setIsSaving(false);
      } else {
        setAlertMessage((prev) => ({
          ...prev,
          display: "flex",
          messageType: "SUCCESS",
          message: "Your website is saved successfully! 3/3 ",
        }));
        setIsSaving(false);
      }
    }
  }

  async function dispatcher() {
    dispatch(
      initalizeUserSlice({
        data: Data,
        pages: Pages,
        index: index,
        isSiteCreated: isSiteCreated,
      })
    );
  }

  const pages_ = useSelector((state) => state.userHtml.pages);
  const domain_ = useSelector((state) => state.Name.subdomain);

  async function upsertWebsite(subdomain) {
    const { data, error } = await supabase
      .from("websites")
      .upsert([{ domain: subdomain, lastUpdate: new Date() }], {
        onConflict: ["domain"],
        returning: "minimal",
      });

    if (error) {
      console.error("Error upserting website:", error.message);
      return null;
    }

    console.log("Upsert successful for subdomain:", subdomain);
    return data;
  }

  const updateIsSiteCreated = async (userId) => {
    try {
      const { data, error } = await supabase
        .from("profiles")
        .update({ isSiteCreated: true })
        .eq("userId", userId); // Ensure you are targeting the correct userId

      if (error) {
        throw error;
      }

      console.log("Record updated successfully:", data);
    } catch (error) {
      console.error("Error updating isSiteCreated:", error.message);
    }
  };

  async function insertWebsite(pages, domain) {
    setIsPublishing(true);
    await saveData();
    const folderPath = `${domain}/`;
    const updatedWebsite = parsePages(pages);

    try {
      const { data: files, error: listError } = await supabase.storage
        .from("hello-world")
        .list(folderPath, { limit: 2000 }); // Adjust limit if necessary

      if (listError) {
        console.error("Error listing files:", listError.message);
        throw listError;
      }

      const deletePromises = files
        .filter((file) => !file.name.endsWith("/")) // Exclude folders
        .map((file) =>
          supabase.storage
            .from("hello-world")
            .remove([`${folderPath}${file.name}`])
        );

      const deleteResults = await Promise.all(deletePromises);

      deleteResults.forEach(({ error, data }, index) => {
        if (error) {
          console.error(`Error deleting ${files[index].name}:`, error.message);
        } else {
          console.log(`Deleted ${files[index].name} successfully.`);
        }
      });

      // Upload new files
      const uploadPromises = Object.entries(updatedWebsite).map(
        ([key, htmlCode]) => {
          const fileName = `${key.toLowerCase()}.html`;
          return supabase.storage
            .from("hello-world")
            .upload(
              `${folderPath}${fileName}`,
              new Blob([htmlCode], { type: "text/html" })
            )
            .then(({ data, error }) => {
              if (error) {
                console.error(`Error uploading ${fileName}:`, error.message);
              } else {
                console.log(`Uploaded ${fileName} successfully.`);
              }
            });
        }
      );

      await upsertWebsite(domain);
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error("Error handling files:", error);
    }

    setIsPublishing(false);
    const url = `https://${domain}.helloworld.to/`;
    window.open(url, "_blank");
  }

  function mergeHtmlTemplates(page) {
    let mergedHtml = "";
    page.forEach((section) => {
      mergedHtml += section.htmlTemplate;
    });
    return mergedHtml;
  }

  function parsePages(pages) {
    const parsedPages = {};
    for (const pageName in pages) {
      if (pages.hasOwnProperty(pageName)) {
        const sections = pages[pageName];
        const mergedHtml = mergeHtmlTemplates(sections);
        const finalHtml = pageHtmlforSaving(subdomainId, mergedHtml);
        parsedPages[pageName] = finalHtml.replace(/#33dollar/g, "$");
      }
    }

    return parsedPages;
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      saveData();
    }, 300000); // 5 minutes = 300,000 ms

    return () => clearTimeout(timeout); // Cleanup on unmount
  }, []); // Empty dependency array ensures it runs once on component mount
  return (
    <>
      <div className="flex flex-row gap-2 items-center justify-center ">
        {!isSaving ? (
          <button
            className="bg-black hover:bg-slate-900 flex items-center justify-center gap-2 font_inter text-white p-0 rounded-md w-auto px-3 h-[40px] "
            onClick={async () => {
              saveData();
            }}
          >
            Save
          </button>
        ) : (
          <p>Saving...</p>
        )}

        {!isPublishing ? (
          <button
            className="bg-black hover:bg-slate-900 flex items-center justify-center gap-2 font_inter text-white p-0 rounded-md w-auto px-3 h-[40px] "
            onClick={() => {
              insertWebsite(pages_, domain_);
            }}
          >
            Preview
          </button>
        ) : (
          <div className="spinner"></div>
        )}
      </div>
    </>
  );
}

export default Publish;

//
