import { XIcon } from "lucide-react";
import React from "react";

const AlertMessage = ({ alertMessage, setAlertMessage }) => {
  return (
    <div
      style={{
        display: alertMessage?.display,
      }}
      className={` ${
        alertMessage.messageType === "SUCCESS"
          ? "bg-green-600 text-white"
          : "bg-red-600 text-white"
      } w-full  z-[100] h-[40px] font-bold items-center justify-center gap-16 `}
    >
      <p> {alertMessage?.message}</p>
      <XIcon
        className="w-4 h-4 hover:text-black cursor-pointer"
        onClick={() => {
          setAlertMessage((prev) => ({ ...prev, display: "hidden" }));
        }}
      />
    </div>
  );
};

export default AlertMessage;
