export function SourceDocument(pageHtml, functionalScript) {
  return `  
    <!DOCTYPE html>
    <html>
    <head>
    <meta charset="utf-8" />
    <script src="https://cdn.tailwindcss.com"></script>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
    <!-- Group 1 -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&family=Montserrat:wght@400;700&family=Lato:wght@400;700&family=Source+Sans+Pro:wght@400;700&display=swap">
    <!-- Group 2 -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Poppins:wght@400;700&family=Raleway:wght@400;700&family=Nunito:wght@400;700&family=Merriweather:wght@400;700&display=swap">
    <!-- Group 3 -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&family=Playfair+Display:wght@400;700&family=Inconsolata:wght@400;700&family=Josefin+Sans:wght@400;700&family=PT+Sans:wght@400;700&display=swap">
    <!-- Group 4 -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&family=Dosis:wght@400;700&family=Rubik:wght@400;700&family=Oswald:wght@400;700&family=Bebas+Neue:wght@400;700&display=swap">
    <!-- Group 5 -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Manrope:wght@400;700&family=Barlow:wght@400;700&family=Exo:wght@400;700&family=Arimo:wght@400;700&display=swap">
    <!-- Group 6 -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&family=Mulish:wght@400;700&family=Asap:wght@400;700&family=Inter:wght@400;700&family=Signika:wght@400;700&display=swap">
    <!-- Group 7 -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Overpass:wght@400;700&family=Fjalla+One:wght@400;700&family=Space+Mono:wght@400;700&family=Karla:wght@400;700&family=Varela+Round:wght@400;700&display=swap">
    <!-- Group 8 -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&family=Titillium+Web:wght@400;700&family=Arvo:wght@400;700&family=Alegreya:wght@400;700&family=Hind:wght@400;700&display=swap">
    <!-- Group 9 -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Catamaran:wght@400;700&family=Yanone+Kaffeesatz:wght@400;700&family=Crimson+Text:wght@400;700&family=Work+Sans:wght@400;700&family=Questrial:wght@400;700&display=swap">
    <!-- Group 10 -->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@400;700&family=Kanit:wght@400;700&family=Cardo:wght@400;700&family=Abel:wght@400;700&family=PT+Serif:wght@400;700&display=swap">

    <link href="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.12/cropper.min.css" rel="stylesheet">
    <!-- Pickr CSS -->
<link rel="stylesheet" href="https://unpkg.com/@simonwep/pickr/dist/themes/classic.min.css" />

<!-- Pickr JS -->
<script src="https://unpkg.com/@simonwep/pickr/dist/pickr.min.js"></script>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
    <style>
      /* Define font classes */
            .font-roboto { font-family: 'Roboto', sans-serif; }
        .font-open-sans { font-family: 'Open Sans', sans-serif; }
        .font-montserrat { font-family: 'Montserrat', sans-serif; }
        .font-lato { font-family: 'Lato', sans-serif; }
        .font-source-sans-pro { font-family: 'Source Sans Pro', sans-serif; }
        .font-roboto-condensed { font-family: 'Roboto Condensed', sans-serif; }
        .font-poppins { font-family: 'Poppins', sans-serif; }
        .font-raleway { font-family: 'Raleway', sans-serif; }
        .font-nunito { font-family: 'Nunito', sans-serif; }
        .font-merriweather { font-family: 'Merriweather', serif; }
        .font-ubuntu { font-family: 'Ubuntu', sans-serif; }
        .font-playfair-display { font-family: 'Playfair Display', serif; }
        .font-inconsolata { font-family: 'Inconsolata', monospace; }
        .font-josefin-sans { font-family: 'Josefin Sans', sans-serif; }
        .font-pt-sans { font-family: 'PT Sans', sans-serif; }
        .font-fira-sans { font-family: 'Fira Sans', sans-serif; }
        .font-dosis { font-family: 'Dosis', sans-serif; }
        .font-rubik { font-family: 'Rubik', sans-serif; }
        .font-oswald { font-family: 'Oswald', sans-serif; }
        .font-bebas-neue { font-family: 'Bebas Neue', sans-serif; }
        .font-quicksand { font-family: 'Quicksand', sans-serif; }
        .font-manrope { font-family: 'Manrope', sans-serif; }
        .font-barlow { font-family: 'Barlow', sans-serif; }
        .font-exo { font-family: 'Exo', sans-serif; }
        .font-arimo { font-family: 'Arimo', sans-serif; }
        .font-heebo { font-family: 'Heebo', sans-serif; }
        .font-mulish { font-family: 'Mulish', sans-serif; }
        .font-asap { font-family: 'Asap', sans-serif; }
        .font-inter { font-family: 'Inter', sans-serif; }
        .font-signika { font-family: 'Signika', sans-serif; }
        .font-overpass { font-family: 'Overpass', sans-serif; }
        .font-fjalla-one { font-family: 'Fjalla One', sans-serif; }
        .font-space-mono { font-family: 'Space Mono', monospace; }
        .font-karla { font-family: 'Karla', sans-serif; }
        .font-varela-round { font-family: 'Varela Round', sans-serif; }
        .font-cabin { font-family: 'Cabin', sans-serif; }
        .font-titillium-web { font-family: 'Titillium Web', sans-serif; }
        .font-arvo { font-family: 'Arvo', serif; }
        .font-alegreya { font-family: 'Alegreya', serif; }
        .font-hind { font-family: 'Hind', sans-serif; }
        .font-catamaran { font-family: 'Catamaran', sans-serif; }
        .font-yanone-kaffeesatz { font-family: 'Yanone Kaffeesatz', sans-serif; }
        .font-crimson-text { font-family: 'Crimson Text', serif; }
        .font-work-sans { font-family: 'Work Sans', sans-serif; }
        .font-questrial { font-family: 'Questrial', sans-serif; }
        .font-zilla-slab { font-family: 'Zilla Slab', serif; }
        .font-kanit { font-family: 'Kanit', sans-serif; }
        .font-cardo { font-family: 'Cardo', serif; }
        .font-abel { font-family: 'Abel', sans-serif; }
        .font-pt-serif { font-family: 'PT Serif', serif; }

  
      /* Additional styles for demonstration */
      .text-preview { font-size: 24px; margin-top: 20px; }
    </style>
    <style>  /* Overlay styles */
    .custom-scrollbar {
    overflow: auto; /* Enable scrolling for the element */
    }


    .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    color: #606060;
    background-color: #606060;
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #bebbbb; /* Customize the scrollbar track color */
    }

    .custom-scrollbar::-webkit-scrollbar-track {
    background-color: rgb(53, 52, 52); /* Customize the scrollbar thumb color */
    }



    
    .overlay {
    position: absolute;
    z-index:9999;
    padding: 5px;
    background-color:rgba(136, 198, 255, 0.30);
    border-radius: 0px;
    border: 2px solid #0085FF;
    display: none;
    
    pointer-events: none; /* Allow interactions with underlying elements */
    }


    
    
    .overlay3 {
        position: absolute;
        z-index: 9999;
        padding: 5px;
        background-color: rgba(255, 255, 0, 0.30); /* Yellow color with opacity */
        border-radius: 0px;
        border: 2px dotted yellow; /* Dotted border with yellow color */
        display: none;
        
        pointer-events: none; /* Allow interactions with underlying elements */
    }

    .zIn{
        z-index:9999;
    }

    .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #36d331; /* Customize the spinner colors */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 0.4s linear infinite;
    }
    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }

    

#colorPickerContainer{
justify-content:space-between;
    align-items:center;
    flex-direction:row;
    display:flex;
    width:full;
}

.slidervalueclass {
    border: 1px solid #d9d9d9; /* Black border with a width of 2px */
    border-radius: 2px;      /* Rounded corners with an 8px radius */
    padding: 4px;            /* Optional: add some padding inside the border */
    font-size: 14px;         /* Optional: adjust font size */
    color: #333;             /* Optional: text color */
    background-color: #f9f9f9; /* Optional: background color */
    display: flex;   /* Optional: adjust display to fit the content */
    width:4rem;
    justify-content:center;
    align-items:center;
    text-align:center
}



/* === range theme and appearance === */
input[type="range"] {
	font-size: 1.5rem;
	width: 10.0rem;
}

input[type="range"] {
	color: #000000;
	--thumb-height: 1.125em;
	--track-height: 0.125em;
	--track-color: rgba(0, 0, 0, 0.2);
	--brightness-hover: 180%;
	--brightness-down: 80%;
	--clip-edges: 0.125em;
}

input[type="range"].win10-thumb {
	color: #ffffff;

	--thumb-height: 1.00em;
	--thumb-width: 0.30em;
	--clip-edges: 0.0125em;
}

@media (prefers-color-scheme: dark) {
	html {
		background-color: #000;
	}

	html::before {
		background: radial-gradient(circle at center, #101112, #000);
	}

	input[type="range"] {
		color: #f07167;
		--track-color: rgba(255, 255, 255, 0.1);
	}

	input[type="range"].win10-thumb {
		color: #3a86ff;
	}
}

/* === range commons === */
input[type="range"] {
	position: relative;
	background: #fff0;
	overflow: hidden;
}

input[type="range"]:active {
	cursor: grabbing;
}

input[type="range"]:disabled {
	filter: grayscale(1);
	opacity: 0.3;
	cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
	position: relative;
}

input[type="range"]::-webkit-slider-thumb {
	--thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
	--clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
	--clip-bottom: calc(var(--thumb-height) - var(--clip-top));
	--clip-further: calc(100% + 1px);
	--box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
		100vmax currentColor;

	width: var(--thumb-width, var(--thumb-height));
	background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
		50% calc(var(--track-height) + 1px);
	background-color: currentColor;
	box-shadow: var(--box-fill);
	border-radius: var(--thumb-width, var(--thumb-height));

	filter: brightness(100%);
	clip-path: polygon(
		100% -1px,
		var(--clip-edges) -1px,
		0 var(--clip-top),
		-100vmax var(--clip-top),
		-100vmax var(--clip-bottom),
		0 var(--clip-bottom),
		var(--clip-edges) 100%,
		var(--clip-further) var(--clip-further)
	);
}

input[type="range"]:hover::-webkit-slider-thumb {
	filter: brightness(var(--brightness-hover));
	cursor: grab;
}

input[type="range"]:active::-webkit-slider-thumb {
	filter: brightness(var(--brightness-down));
	cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
	background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
		100% calc(var(--track-height) + 1px);
}

input[type="range"]:disabled::-webkit-slider-thumb {
	cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
	appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
	background: #fff0;
}

input[type="range"]::-moz-range-thumb {
	background: currentColor;
	border: 0;
	width: var(--thumb-width, var(--thumb-height));
	border-radius: var(--thumb-width, var(--thumb-height));
	cursor: grab;
}

input[type="range"]:active::-moz-range-thumb {
	cursor: grabbing;
}

input[type="range"]::-moz-range-track {
	width: 100%;
	background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
	appearance: none;
	background: currentColor;
	transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
	height: calc(var(--track-height) + 1px);
	border-radius: var(--track-height);
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
	filter: brightness(100%);
}

input[type="range"]:hover::-moz-range-thumb,
input[type="range"]:hover::-moz-range-progress {
	filter: brightness(var(--brightness-hover));
}

input[type="range"]:active::-moz-range-thumb,
input[type="range"]:active::-moz-range-progress {
	filter: brightness(var(--brightness-down));
}

input[type="range"]:disabled::-moz-range-thumb {
	cursor: not-allowed;
}



    /* Overlay styles */
    .overlay2 {
    position: absolute;
    margin: 0;
    padding: 0;
    left :0;
    z-index: 10;
    background-color:rgba(136, 198, 255, 0.30);
    border-radius: 0px;
    border: 2px solid #0085FF;
    box-sizing: border-box;
    display: none;
    pointer-events: none; 
    overflow: hidden;
    }


    #sliderContainer{
display: flex;              /* Equivalent to Tailwind's 'flex' */
    flex-direction: row;        /* Equivalent to Tailwind's 'flex-row' */
    justify-content: space-between; /* Equivalent to Tailwind's 'justify-between' */
    width: 100%;                /* Equivalent to Tailwind's 'w-full' */
    align-items: center;       
    gap: 7px
     padding-left: 1.25rem;   /* Equivalent to Tailwind's 'px-5' */
    padding-right: 1.25rem;  /* Equivalent to Tailwind's 'px-5' */
    }
    textarea {
    border: none;
    border-color: transparent;
    }
        
    textarea:focus {
    outline: none;
    }           
    </style>
    <title>Mobile-sized Window</title>
    </head>
    <body class="custom-scrollbar  ">
    <div id="overlay2" class="overlay2">
    
    
    
    </div>
    <div id="overlay" class="overlay"></div>
    <div id="overlay3" class="overlay3"></div>

  
    <button id="original" name="background-image-editor-button" class="bg-black text-white rounded-lg w-auto hidden items-center justify-center gap-2 py-2 px-3">
    <svg name="background-image-editor-pencil" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"/><path d="m15 5 4 4"/></svg>
    <p>Edit Background</p>
    </button>

     <button id="original"  name="background-image-editor-close-button" class="bg-black text-white rounded-full w-10 h-10 hidden items-center justify-center">
        <svg name="background-image-editor-cross"  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
    </button>



  
   

  
   


    <div id="userControls" class='w-[380px]  absolute p-2 z-[9999] h-auto  box-border bg-white rounded-xl shadow-md border-2 border-gray-200 hidden flex-col justify-between'> 
       
     
       <div id="textEditor" class="hidden flex-col px-3 rounded-lg zIn">
            <textarea id="textEditorArea" class="w-auto h-auto border-4 border-black  cursor-text rounded-lg" placeholder="Enter text here..."></textarea>
            <div class="flex justify-end w-full items-center mt-4 flex-row">                  
            <button id="UpdateText" class="font_inter w-auto rounded-md  py-1 px-5 text-white bg-black">
                        Update
            </button>

            </div>                                      
        </div>

    
    
        <div  id="PromptEditor" class="flex flex-col p-0 w-full h-[80%] zIn">
                <textarea
                    id="textInput"
                    placeholder="What do you want to edit?"
                    class="font_inter resize-none placeholder-[#71717a] h-full custom-scrollbar w-full rounded-md border-none p-2 focus:ring ring-transparent"
                ></textarea>     

        </div>


        <div id="imageEditor" class="hidden flex-col p-0 w-auto h-[80%] zIn">
            <div class="flex flex-row gap-2">
                <label for="uploadInput" class="font-inter relative cursor-pointer bg-gray-100 border border-gray-300 rounded-md px-4 w-[100px] items-center h-[30px] text-gray-600 text-xs flex  space-x-2 hover:border-black hover:bg-gray-200">
                    <span class="text-gray-800">Choose File</span>
                    <input type="file" id="uploadInput" class="hidden">
                
                </label>
                <p id="imageFileName">No file Choosen</p>

            </div>
            <div id="displayImage" class="mt-2 flex justify-center items-center w-auto"></div>
            <button id="updateImage" class="font-inter w-[100px] mt-2 self-end rounded-md py-1 px-5 text-white bg-black">
                Update
            </button>
        </div>


        <div id="linkEditor" class="hidden flex-col rounded-lg zIn h-full justify-between">
            <input type="text" id="linkEditorInput" class="w-auto h-[40px] p-3 border border-gray-300 cursor-text rounded-md text-blue-400" placeholder="Enter Link here...">
            <div class="flex justify-end w-full items-center mt-2 flex-row">                  
                <button id="updateLink" class="font_inter w-auto rounded-md py-1 px-5 text-white bg-black">
                    Update
                </button>
            </div>                                      
        </div>


        <div id="fontEditor" class="hidden flex-col rounded-lg zIn h-full justify-between">
     <select id="fontDropdown" onchange="applyFont(this.value)">
  <option value="font-roboto" class="font-roboto" style="font-size: 22px;">Roboto</option>
  <option value="font-open-sans" class="font-open-sans" style="font-size: 22px;">Open Sans</option>
  <option value="font-montserrat" class="font-montserrat" style="font-size: 22px;">Montserrat</option>
  <option value="font-lato" class="font-lato" style="font-size: 22px;">Lato</option>
  <option value="font-source-sans-pro" class="font-source-sans-pro" style="font-size: 22px;">Source Sans Pro</option>
  <option value="font-roboto-condensed" class="font-roboto-condensed" style="font-size: 22px;">Roboto Condensed</option>
  <option value="font-poppins" class="font-poppins" style="font-size: 22px;">Poppins</option>
  <option value="font-raleway" class="font-raleway" style="font-size: 22px;">Raleway</option>
  <option value="font-nunito" class="font-nunito" style="font-size: 22px;">Nunito</option>
  <option value="font-merriweather" class="font-merriweather" style="font-size: 22px;">Merriweather</option>
  <option value="font-ubuntu" class="font-ubuntu" style="font-size: 22px;">Ubuntu</option>
  <option value="font-playfair-display" class="font-playfair-display" style="font-size: 22px;">Playfair Display</option>
  <option value="font-inconsolata" class="font-inconsolata" style="font-size: 22px;">Inconsolata</option>
  <option value="font-josefin-sans" class="font-josefin-sans" style="font-size: 22px;">Josefin Sans</option>
  <option value="font-pt-sans" class="font-pt-sans" style="font-size: 22px;">PT Sans</option>
  <option value="font-fira-sans" class="font-fira-sans" style="font-size: 22px;">Fira Sans</option>
  <option value="font-dosis" class="font-dosis" style="font-size: 22px;">Dosis</option>
  <option value="font-rubik" class="font-rubik" style="font-size: 22px;">Rubik</option>
  <option value="font-oswald" class="font-oswald" style="font-size: 22px;">Oswald</option>
  <option value="font-bebas-neue" class="font-bebas-neue" style="font-size: 22px;">Bebas Neue</option>
  <option value="font-quicksand" class="font-quicksand" style="font-size: 22px;">Quicksand</option>
  <option value="font-manrope" class="font-manrope" style="font-size: 22px;">Manrope</option>
  <option value="font-barlow" class="font-barlow" style="font-size: 22px;">Barlow</option>
  <option value="font-exo" class="font-exo" style="font-size: 22px;">Exo</option>
  <option value="font-arimo" class="font-arimo" style="font-size: 22px;">Arimo</option>
  <option value="font-heebo" class="font-heebo" style="font-size: 22px;">Heebo</option>
  <option value="font-mulish" class="font-mulish" style="font-size: 22px;">Mulish</option>
  <option value="font-asap" class="font-asap" style="font-size: 22px;">Asap</option>
  <option value="font-inter" class="font-inter" style="font-size: 22px;">Inter</option>
  <option value="font-signika" class="font-signika" style="font-size: 22px;">Signika</option>
  <option value="font-overpass" class="font-overpass" style="font-size: 22px;">Overpass</option>
  <option value="font-fjalla-one" class="font-fjalla-one" style="font-size: 22px;">Fjalla One</option>
  <option value="font-space-mono" class="font-space-mono" style="font-size: 22px;">Space Mono</option>
  <option value="font-karla" class="font-karla" style="font-size: 22px;">Karla</option>
  <option value="font-varela-round" class="font-varela-round" style="font-size: 22px;">Varela Round</option>
  <option value="font-cabin" class="font-cabin" style="font-size: 22px;">Cabin</option>
  <option value="font-titillium-web" class="font-titillium-web" style="font-size: 22px;">Titillium Web</option>
  <option value="font-arvo" class="font-arvo" style="font-size: 22px;">Arvo</option>
  <option value="font-alegreya" class="font-alegreya" style="font-size: 22px;">Alegreya</option>
  <option value="font-hind" class="font-hind" style="font-size: 22px;">Hind</option>
  <option value="font-catamaran" class="font-catamaran" style="font-size: 22px;">Catamaran</option>
  <option value="font-yanone-kaffeesatz" class="font-yanone-kaffeesatz" style="font-size: 22px;">Yanone Kaffeesatz</option>
  <option value="font-crimson-text" class="font-crimson-text" style="font-size: 22px;">Crimson Text</option>
  <option value="font-work-sans" class="font-work-sans" style="font-size: 22px;">Work Sans</option>
  <option value="font-questrial" class="font-questrial" style="font-size: 22px;">Questrial</option>
  <option value="font-zilla-slab" class="font-zilla-slab" style="font-size: 22px;">Zilla Slab</option>
  <option value="font-kanit" class="font-kanit" style="font-size: 22px;">Kanit</option>
  <option value="font-cardo" class="font-cardo" style="font-size: 22px;">Cardo</option>
  <option value="font-abel" class="font-abel" style="font-size: 22px;">Abel</option>
  <option value="font-pt-serif" class="font-pt-serif" style="font-size: 22px;">PT Serif</option>
</select>

        

        <div class="flex justify-end w-full items-center mt-2 flex-row">                  
            <button id="updateFont" class="font_inter w-auto rounded-md py-1 px-5 text-white bg-black">
                Update
            </button>
        </div>                                      
        </div>


       
        
        <div id="controls" class='flex justify-between gap-1 w-full items-center h-[20%] mt-2 flex-row zIn'>
            <div id="textControls" class="hidden flex-row gap-1">
            <button id="editTextButton-tc" class="font_inter hover:border  hover:border-black bg-gray-100  text-gray-600   px-4 h-[30px] rounded-md text-xs flex items-center space-x-2">
            Edit Text
            </button>
            <button id="editFontButton-tc" class="font_inter hover:border hover:border-black bg-gray-100  text-gray-600   px-4 h-[30px] rounded-md text-xs flex items-center space-x-2">
            Edit Font
            </button>
            </div>





            <div id="linkControls" class="hidden flex-row gap-1">
            <button id="editTextButton-lc" class="font_inter hover:border  hover:border-black bg-gray-100  text-gray-600   px-4 h-[30px] rounded-md text-xs flex items-center space-x-2">
            Edit Text
            </button>
            <button id="editFontButton-lc" class="font_inter hover:border hover:border-black bg-gray-100  text-gray-600   px-4 h-[30px] rounded-md text-xs flex items-center space-x-2">
                    Edit Font
            </button>
            <button id="editLinkButton-lc" class="font_inter hover:border hover:border-black bg-gray-100  text-gray-600   px-4 h-[30px] rounded-md text-xs flex items-center space-x-2">
                    Edit Link
            </button>
            </div>





            <div id="imageControls" class="hidden flex-row gap-1">
            <button id="openImage-ic" class="font_inter hover:border  hover:border-black bg-gray-100  text-gray-600   px-4 h-[30px] rounded-md text-xs flex items-center space-x-2">
            Open Image
            </button>
           
            </div>





            <div id="buttonControls" class="hidden flex-row gap-1">
            <button id="editText-bc" class="font_inter hover:border  hover:border-black bg-gray-100  text-gray-600   px-4 h-[30px] rounded-md text-xs flex items-center space-x-2">
             Edit Text
            </button>
           
            <button id="editEvent-bc" class="font_inter hover:border  hover:border-black bg-gray-100  text-gray-600   px-4 h-[30px] rounded-md text-xs flex items-center space-x-2">
            Edit Text
           </button>
          
            </div>



            <div name="update-controls" class='flex flex-row gap-1 items-center '>
            <button id="saveButton" class="font_inter bg-black text-white px-4 h-[30px] rounded-md text-xs flex items-center space-x-2">
            <div  id='spinner'  class='hidden spinner mr-2 h-2 w-2'></div>    
                    Update
            </button>


            
           
            </div>
        </div>
    </div>
    <div id="778899" class="bg-white w-full h-full">
   ${pageHtml}

    </div>

   
   <div class="w-full h-[200px] bg-gray-300"></div>
   
    <script>
    const allLinks = document.querySelectorAll('a');
    allLinks.forEach(link => {
        link.addEventListener('click', function(event) {
            event.preventDefault();
        });
    });
    </script>

    <script src="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.12/cropper.min.js"></script>
   


    <script>
        const inputField = document.getElementById('textInput');
        const editorDiv = document.getElementById('PromptEditor');

        inputField.addEventListener('input', () => {
            const inputValue = inputField.value;

            if (inputValue.includes('#pixel')) {
                if (!document.getElementById('sliderContainer')) {
                    const sliderContainer = document.createElement('div');
                    sliderContainer.id = 'sliderContainer';

                    const slider = document.createElement('input');
                    slider.type = 'range';
                    slider.min = '0';
                    slider.max = '100';
                    slider.value = '0';
                    slider.step= '1'
                    slider.id = 'slider';

                    const sliderValueDisplay = document.createElement('p');
                    sliderValueDisplay.id = 'sliderVal';
                    sliderValueDisplay.textContent = slider.value;
sliderValueDisplay.classList.add('slidervalueclass');
                    const doneButton = document.createElement('button');
                    doneButton.innerText = 'Done';
                    doneButton.id = 'doneButton';

                  
                    sliderContainer.appendChild(sliderValueDisplay);
                    sliderContainer.appendChild(slider);
                    sliderContainer.appendChild(doneButton);
                    editorDiv.appendChild(sliderContainer);

                    slider.addEventListener('input', () => {
                        sliderValueDisplay.textContent = slider.value;
                    });

                    doneButton.addEventListener('click', () => {
                        const sliderValue = slider.value;
                        const sval = sliderValue.toString() + ' px'
                        const updatedValue = inputField.value.replace('#pixel', sval);
                        inputField.value = updatedValue;
                        editorDiv.removeChild(sliderContainer);
                    });
                }
            } else  if (inputValue.includes('#color')) {
 
            if (!document.getElementById('colorPickerContainer')) {
                const colorPickerContainer = document.createElement('div');
                colorPickerContainer.id = 'colorPickerContainer';

                // Create a color box element
                const colorBox = document.createElement('div');
                colorBox.id = 'colorBox';
                colorBox.style.width = '50px';
                colorBox.style.height = '50px';
                colorBox.style.border = '1px solid #ccc';
                colorBox.style.borderRadius = '4px';

                // Create the color picker element
                const colorPickerDiv = document.createElement('div');
                colorPickerDiv.id = 'colorPicker';

                // Create a done button
                const doneButton = document.createElement('button');
                doneButton.innerText = 'Done';
                doneButton.id = 'doneButton';

                colorPickerContainer.appendChild(colorBox);
                colorPickerContainer.appendChild(colorPickerDiv);
                colorPickerContainer.appendChild(doneButton);
                editorDiv.appendChild(colorPickerContainer);

                // Initialize Pickr color picker
                const pickr = Pickr.create({
                    el: '#colorPicker',
                    theme: 'classic', // or 'monolith', or 'nano'
                    swatches: [
                        'rgba(244, 67, 54, 1)',
                        'rgba(233, 30, 99, 1)',
                        'rgba(156, 39, 176, 1)',
                        'rgba(103, 58, 183, 1)',
                        'rgba(63, 81, 181, 1)',
                        'rgba(33, 150, 243, 1)',
                        'rgba(3, 169, 244, 1)',
                        'rgba(0, 188, 212, 1)',
                        'rgba(0, 150, 136, 1)',
                        'rgba(76, 175, 80, 1)',
                        'rgba(139, 195, 74, 1)',
                        'rgba(205, 220, 57, 1)',
                        'rgba(255, 235, 59, 1)',
                        'rgba(255, 193, 7, 1)',
                        'rgba(255, 87, 34, 1)',
                        'rgba(255, 87, 34, 1)',
                    ],
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            input: true,
                            clear: true,
                            save: true
                        }
                    }
                });

                // Update the color box and input field when the color changes
                pickr.on('change', function(color) {
                    const colorValue = color.toHEXA().toString();
                    colorBox.style.backgroundColor = colorValue;
                });

                doneButton.addEventListener('click', () => {
                    const colorValue = pickr.getColor().toHEXA().toString();
                    const colorBox = document.getElementById('colorBox');
                    const colorBoxHtml = '<span style="display: inline-block; width: 20px; height: 20px; background-color: ' + colorValue + '; border: 1px solid #ccc; border-radius: 4px;"></span>';
                    const updatedValue = inputField.value.replace('#color', colorValue);
                    inputField.value = updatedValue;
                    editorDiv.removeChild(colorPickerContainer);
                });
            }
        } else {
            const colorPickerContainer = document.getElementById('colorPickerContainer');
            if (colorPickerContainer) {
                editorDiv.removeChild(colorPickerContainer);
            }
                 const sliderContainer = document.getElementById('sliderContainer');
                if (sliderContainer) {
                    editorDiv.removeChild(sliderContainer);
                }
        } 
                
            
            
        });
    </script>

<script>
    function toggleAccordion(index) {
        const items = document.querySelectorAll('.accordion-item');
        const targetItem = items[index];
        
        // Exit if the target item does not exist
        if (!targetItem) return;

        const content = targetItem.querySelector('[name="accordion-content"]');
        const chevron = targetItem.querySelector('[name="chevron"]');
        
        // Exit if content or chevron elements do not exist
        if (!content || !chevron) return;

        // Hide all items
        items.forEach((item, i) => {
            if (i !== index) {
                const itemContent = item.querySelector('[name="accordion-content"]');
                const itemChevron = item.querySelector('[name="chevron"]');
                
                if (itemContent) itemContent.classList.add('hidden');
                if (itemChevron) itemChevron.classList.remove('rotate-180');
            }
        });

        // Toggle the selected item
        content.classList.toggle('hidden');
        chevron.classList.toggle('rotate-180');
    }
</script>


    <script src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js"></script>



    ${functionalScript.flat(Infinity).join("\n")}
    </body>
    </html> 
    `;
}

export function UserWebsite(pageHtml) {
  return `  
    <!DOCTYPE html>
    <html>
    <head>
    <meta charset="utf-8" />
    <script src="https://cdn.tailwindcss.com"></script    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
    </head>
    <body class="custom-scrollbar">
   
   ${pageHtml}

    
    </body>
    </html> 
    `;
}
