import Card from "./Card";
import SiteCard from "./SiteCard";
import AnalyticsGraph from "../Analytics/AnalyticsGraph";
import { useSelector } from "react-redux";
import supabase from "../../../../config/supabse";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../SignIn/spinner.css";
function Overview() {
  const { credId } = useParams();
  const [token, setToken] = useState("");

  useEffect(() => {
    const getSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error retrieving session:", error.message);
        return;
      }
      if (data) {
        if (
          data &&
          data.session &&
          data.session.user.id &&
          data.session.access_token
        ) {
          let accessToken = data.session.access_token;

          setToken(accessToken);
        }
      }
    };
    getSession();
  }, []);
  const navigate = useNavigate();

  const Name = useSelector((state) => {
    return state.Name.data;
  });
  const pageHtml = useSelector((state) => {
    return state.userHtml.pageHtml;
  });
  const subDomain = useSelector((state) => {
    return state.Name.subdomain;
  });
  const [data, setData] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [unReadCount, setUnReadCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      console.log("SubDomain : ", subDomain);
      try {
        const { data: fetchedData, error } = await supabase
          .from("websites") // Replace with your table name
          .select("*")
          .eq("domain", `${subDomain}`); // Replace 'domain' with your column name

        if (error) throw error;
        setData(fetchedData[0]);
        console.log("this", fetchedData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchSessions = async () => {
      setLoading(true);
      try {
        const { data: fetchedData, error } = await supabase
          .from("sessions") // Replace with your table name
          .select("*")
          .eq("siteId", `${subDomain}`); // Replace 'domain' with your column name

        if (error) throw error;
        setSessions(fetchedData);

        console.log("this ---- >", fetchedData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchUnreadMessages(subDomainId) {
      try {
        const { data, error } = await supabase
          .from("userMessages")
          .select("*")
          .eq("siteId", subDomainId)
          .eq("isRead", false);

        if (error) {
          throw error;
        }

        console.log("Unread messages fetched successfully:", data);
        setUnReadCount(data.length);
        return data;
      } catch (error) {
        console.error("Error fetching unread messages:", error);
        throw error; // Optionally rethrow error for further handling
      }
    }

    fetchUnreadMessages(subDomain);
  }, []);

  return (
    <>
      {!Loading ? (
        <>
          <div className="py-6 px-10">
            <div className="text-[#000]">
              <h2 className="text-[38px] font-medium font-manrope">Today</h2>
              <p className="text-zinc-600 text-sm ml-2">
                {new Date().toLocaleString("default", { month: "long" })}{" "}
                {new Date().getDate()}
              </p>
            </div>

            <div className="flex flex-row  gap-5">
              <div className="flex flex-col mt-5 gap-5 w-[50%] ">
                <div className="flex flex-wrap items-center gap-5 w-auto ">
                  <div className="overview-section  bg-white relative bg-cover bg-center h-[35vh] overflow-hidden w-[40vw] flex items-center justify-center  rounded-2xl border-2 border-gray-100  ">
                    {pageHtml ? (
                      <iframe
                        className="flex items-center justify-center"
                        width="1680" // Fixed desktop width
                        height="768" // Fixed desktop height
                        title="WebSite"
                        style={{
                          border: "none",
                          pointerEvents: "none", // Disable interactivity
                          overflow: "hidden", // Hide any scrollbars

                          borderRadius: "10px", // Rounded border
                        }}
                        srcDoc={`
                          <!DOCTYPE html>
                        <html lang='en'>
                        <head>
  
                            <meta charset='UTF-8'>
                     
                              <script src="https://cdn.tailwindcss.com"></script>
                              <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                              <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400|Open+Sans:ital,wght@0,400;0,700;1,400|Montserrat:ital,wght@0,400;0,700;1,400|Lato:ital,wght@0,400;0,700;1,400|Source+Sans+Pro:ital,wght@0,400;0,700;1,400|Roboto+Condensed:ital,wght@0,400;0,700;1,400|Arial:ital,wght@0,400;0,700;1,400|Verdana:ital,wght@0,400;0,700;1,400|Helvetica:ital,wght@0,400;0,700;1,400|Georgia:ital,wght@0,400;0,700;1,400&display=swap">
                              <link href="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.12/cropper.min.css" rel="stylesheet">
                              <!-- Pickr CSS -->
                          <link rel="stylesheet" href="https://unpkg.com/@simonwep/pickr/dist/themes/classic.min.css" />
  
                          <!-- Pickr JS -->
                          <script src="https://unpkg.com/@simonwep/pickr/dist/pickr.min.js"></script>
                              <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                                rel="stylesheet">
                            <title>Embedded HTML</title>
                            <style>
                                body { font-family: Arial, sans-serif; margin: 20px; }
                                h1 { color: #333; }
                                p { color: #666; }
                            </style>
                        </head>
                        <body>
                            ${pageHtml}
                        </body>
                        </html>
                        `}
                      ></iframe>
                    ) : (
                      <iframe
                        className="flex items-center justify-center"
                        width="1380" // Fixed desktop width
                        height="768" // Fixed desktop height
                        title="WebSite"
                        style={{
                          border: "none",
                          pointerEvents: "none", // Disable interactivity
                          overflow: "hidden", // Hide any scrollbars
                          transformOrigin: "top left", // Ensure scaling starts from the top-left
                          borderRadius: "10px", // Rounded border
                        }}
                        srcDoc={`
                          <!DOCTYPE html>
                        <html lang='en'>
                        <head>
  
                            <meta charset='UTF-8'>
                     
                              <script src="https://cdn.tailwindcss.com"></script>
                              <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                              <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400|Open+Sans:ital,wght@0,400;0,700;1,400|Montserrat:ital,wght@0,400;0,700;1,400|Lato:ital,wght@0,400;0,700;1,400|Source+Sans+Pro:ital,wght@0,400;0,700;1,400|Roboto+Condensed:ital,wght@0,400;0,700;1,400|Arial:ital,wght@0,400;0,700;1,400|Verdana:ital,wght@0,400;0,700;1,400|Helvetica:ital,wght@0,400;0,700;1,400|Georgia:ital,wght@0,400;0,700;1,400&display=swap">
                              <link href="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.12/cropper.min.css" rel="stylesheet">
                              <!-- Pickr CSS -->
                          <link rel="stylesheet" href="https://unpkg.com/@simonwep/pickr/dist/themes/classic.min.css" />
  
                          <!-- Pickr JS -->
                          <script src="https://unpkg.com/@simonwep/pickr/dist/pickr.min.js"></script>
                              <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
                                rel="stylesheet">
                            <title>Embedded HTML</title>
                            <style>
                                body { font-family: Arial, sans-serif; margin: 20px; }
                                h1 { color: #333; }
                                p { color: #666; }
                            </style>
                        </head>
                        <body>
                           <h1>WELCOME</h1>
                        </body>
                        </html>
                        `}
                      ></iframe>
                    )}

                    <div className="bg-gray-50 absolute bottom-0 py-3 px-5 flex w-full h-[60px] self-end justify-self-end flex-row items-center justify-between">
                      <h2 className="font-manrope font-semibold leading-[26px] text-black ">
                        Website
                      </h2>
                      <div className="flex items-center flex-row justify-center gap-1 mt-1">
                        {token && (
                          <button
                            type="button"
                            onClick={() => {
                              navigate(`/loading/${credId}/${token}/1`);
                            }}
                            className="text-[#000000]  bg-white font-manrope border-2 border-gray-200 hover:border-gray-300 font-medium rounded-lg text-sm px-9 py-2 me-2 mb-2 "
                          >
                            Edit
                          </button>
                        )}
                        {data && data.domain && (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://${data.domain}.helloworld.to`}
                            className="text-[#000000]  bg-[#fccf82] font-manrope font-medium rounded-lg text-sm px-6 py-2 me-2 mb-2 "
                          >
                            Preview
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <SiteCard /> */}
                </div>
                <div className="flex flex-wrap items-center gap-5  w-auto  ">
                  <div className="overview-section relative bg-cover bg-center bg-white border-2 border-gray-100 h-[35vh] overflow-hidden w-[40vw]  rounded-2xl ">
                    <div className="h-full flex items-center justify-center w-full ">
                      <div className="h-full p-6 flex flex-col w-full justify-between   ">
                        <h2 className="text-sm font-semibold px-3">
                          Visits per day
                        </h2>

                        <div className="mt-4 w-full h-full px-3">
                          <AnalyticsGraph height={"20vh"} data={sessions} />
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 absolute bottom-0 py-3 px-5 flex w-full h-[60px] self-end justify-self-end flex-row items-center justify-between">
                      <h2 className="font-manrope font-semibold leading-[26px] text-black ">
                        Analytics
                      </h2>
                      <div className="flex items-center flex-row justify-center gap-1 mt-1">
                        <button
                          type="button"
                          onClick={() => {
                            navigate(`/dashboard/${credId}/analytics`);
                          }}
                          className="bg-white border-2 border-gray-200 hover:border-gray-300 px-6 py-1 rounded-lg"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <Card /> */}
                </div>
              </div>

              <div className="flex flex-col gap-5 mt-5">
                <SiteCard data={data} />

                <Card data={data} unReadCount={unReadCount} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex w-full h-full items-center justify-center">
          <div className="spinner"></div>
        </div>
      )}
    </>
  );
}
export default Overview;
