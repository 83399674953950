import { createSlice } from "@reduxjs/toolkit";
import { arrayMove } from "@dnd-kit/sortable";

// Reducer

function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
function copyNavLinksToMobile(navHtml) {
  // Create a new HTML document to properly simulate a DOM
  const tempDoc = document.implementation.createHTMLDocument("temp");
  tempDoc.body.innerHTML = navHtml; // Set the inner HTML of the new document

  // Select nav containers and mobile nav containers using getElementsByName
  const navContainers = tempDoc.getElementsByName("nav-link-container");
  const mobileNavContainers = tempDoc.getElementsByName(
    "nav-link-container-mobile"
  );

  console.log("NavContainers =>", navContainers);

  let links = [];
  if (navContainers.length > 0) {
    const navContainer = navContainers[0]; // Assuming there's only one container
    // Get only the links inside the container
    links = navContainer.querySelectorAll('a[name="nav-link"]');
  } else {
    console.error("Nav container not found.");
  }

  console.log("Mobile nav container found:", mobileNavContainers);

  // Adjust z-index of "fixed" child inside "mobile-menu"
  const mobileMenus = tempDoc.getElementsByName("mobile-menu");
  if (mobileMenus.length > 0) {
    const mobileMenu = mobileMenus[0];
    const fixedChild = mobileMenu.querySelector(".fixed");
    if (fixedChild) {
      fixedChild.style.zIndex = "100";
      console.log("Updated z-index of fixed child:", fixedChild);
    } else {
      console.warn('No child with class "fixed" found inside mobile-menu.');
    }
  } else {
    console.warn('No div with name="mobile-menu" found.');
  }

  // Replace the mobileNavContainer with a new div
  if (mobileNavContainers.length > 0) {
    const mobileNavContainer = mobileNavContainers[0];

    // Create a new div and copy attributes from the original
    const newMobileNavContainer = document.createElement("div");
    newMobileNavContainer.name = mobileNavContainer.getAttribute("name");
    newMobileNavContainer.id = mobileNavContainer.id || ""; // Copy ID if it exists
    newMobileNavContainer.className = mobileNavContainer.className; // Copy classes

    console.log("New container created:", newMobileNavContainer);

    // Append the cloned links to the new div
    links.forEach((link) => {
      const clonedLink = link.cloneNode(true);
      clonedLink.classList.add(
        "-mx-3",
        "block",
        "rounded-lg",
        "px-3",
        "py-2",
        "text-base",
        "font-semibold",
        "leading-7",
        "text-gray-900",
        "hover:bg-gray-50"
      );
      newMobileNavContainer.appendChild(clonedLink);
    });

    // Replace the old container with the new one
    mobileNavContainer.parentNode.replaceChild(
      newMobileNavContainer,
      mobileNavContainer
    );

    console.log("Replaced old mobile nav container with new container.");

    // Get updated HTML after modifications
    const newNavHtml = tempDoc.body.innerHTML;
    return newNavHtml;
  } else {
    console.error("Mobile nav container not found.");
    return navHtml;
  }
}

const cleanUpPages = (pages) => {
  const cleanedPages = { ...pages };
  if (
    cleanedPages &&
    cleanedPages.hasOwnProperty("undefined") &&
    Array.isArray(cleanedPages.undefined) &&
    cleanedPages.undefined?.length === 0
  ) {
    delete cleanedPages.undefined;
  }
  return cleanedPages;
};

const userHtmlSlice = createSlice({
  name: "userHtml",
  initialState: {
    data: null,
    pages: null,
    script: null,
    sectionSeparators: null,
    index: "Home",
    isSiteCreated: 0,
    history: [],
    pageHtml: null,
  },
  reducers: {
    setPageHtmlState: (state, action) => {
      state.pageHtml = action.payload;
    },

    savePageState: (state, action) => {
      state.pages[action.payload.pageIndex] = action.payload.pageState;
    },

    initalizeUserSlice: (state, action) => {
      console.log(action.payload.data);
      state.data = action.payload.pages["Home"];
      state.isSiteCreated = action.payload.isSiteCreated;
      state.index = action.payload.index;
      state.history = [];
      state.pages = action.payload.pages;
      state.sectionSeparators = [];
      state.script = [];
    },

    setUserHtml: (state, action) => {
      state.data = state.pages["Home"];
    },

    setPages: (state, action) => {
      state.pages = action.payload;
      state.index = 0;
    },

    setPageIndex: (state, action) => {
      state.index = action.payload;
    },

    setIsSiteCreated: (state, action) => {
      state.isSiteCreated = action.payload;
    },

    setHistory: (state, action) => {
      state.history = action.payload;
    },

    addToHistory: (state) => {
      console.log("Added to the stack", state.history);
    },

    // Action to revert to previous state
    undo: (state, action) => {
      if (state.history && state.history.length > 0) {
        const poppedElement = state.history.pop(); // Remove and get the last element
        if (poppedElement && poppedElement.pages) {
          state.pages = poppedElement.pages; // Restore the pages from the popped history item
          console.log("Undo successful. Restored pages:", poppedElement.pages);
        } else {
          console.warn(
            "Popped element does not contain valid pages. Undo operation skipped."
          );
        }
      } else {
        console.warn("No history to undo. Operation skipped.");
      }
    },

    // Action to add a new page
    addPage: (state, action) => {
      const { pageName } = action.payload;
      if (pageName !== undefined) {
        // Ensure that state.pages is initialized as an object
        if (!state.pages) {
          state.pages = {};
        }
        state.pages[pageName] = []; // Initialize the new page with an empty array
      }
      state.pages = cleanUpPages(state.pages);
    },

    duplicateRenamePage: (state, action) => {
      const { pageName, newName } = action.payload;
      if (pageName !== undefined && newName !== undefined) {
        if (!state.pages) {
          state.pages = {};
        }
        let finalNewName = newName;
        if (state.pages.hasOwnProperty(newName)) {
          let counter = 1;
          while (state.pages.hasOwnProperty(finalNewName)) {
            finalNewName = `${newName}_${counter}`;
            counter++;
          }
        }
        if (state.pages.hasOwnProperty(pageName)) {
          const originalPage = state.pages[pageName];
          const duplicatedPage = JSON.parse(JSON.stringify(originalPage));
          state.pages[finalNewName] = duplicatedPage;
        } else {
          console.log(`Page '${pageName}' not found.`);
        }
      }
      state.pages = cleanUpPages(state.pages);
    },

    deletePage: (state, action) => {
      const { pageName } = action.payload;
      if (pageName !== undefined) {
        if (!state.pages) {
          state.pages = {};
        }
        if (state.pages.hasOwnProperty(pageName)) {
          // Delete the original page
          delete state.pages[pageName];
        } else {
          console.log(`Page '${pageName}' not found.`);
        }
      }
    },

    addSectionToPage: (state, action) => {
      const { pageName, section } = action.payload;

      // Check if the page exists in the state
      if (state.pages && state.pages[pageName]) {
        // Add the new section to the page
        state.pages[pageName].push(section);

        // Separate the sections into different categories
        const navbarSections = state.pages[pageName].filter(
          (sec) => sec.sectionType === 0
        );
        const heroSections = state.pages[pageName].filter(
          (sec) => sec.sectionType === 1
        );
        const footerSections = state.pages[pageName].filter(
          (sec) => sec.sectionType === 9
        );
        const otherSections = state.pages[pageName].filter(
          (sec) =>
            sec.sectionType !== 0 &&
            sec.sectionType !== 1 &&
            sec.sectionType !== 9
        );

        // Combine them in the desired order
        state.pages[pageName] = [
          ...navbarSections,
          ...heroSections,
          ...otherSections,
          ...footerSections,
        ];
      }
    },
    insertDuplicateHtml: (state, action) => {
      const { index, dupId } = action.payload;

      // Search for the `dupSection` across all pages
      let dupSection = null;
      for (const pageKey in state.pages) {
        if (state.pages[pageKey]) {
          dupSection = state.pages[pageKey].find(
            (section) => section._id === dupId
          );
          if (dupSection) break; // Stop searching once found
        }
      }

      if (dupSection && state.pages[state.index]?.length > 2) {
        // Find the section where the duplicate should be inserted
        const indexSection = state.pages[state.index].find(
          (section) => section._id === index
        );

        if (indexSection) {
          const indexSectionIdx =
            state.pages[state.index].indexOf(indexSection);
          const generateRandomId = () => {
            const chars =
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            return Array.from(
              { length: 12 },
              () => chars[Math.floor(Math.random() * chars.length)]
            ).join("");
          };

          const newDupSection = { ...dupSection, _id: generateRandomId() };
          // Find the index of the section

          // Insert a copy of `dupSection` below `indexSection`
          state.pages[state.index].splice(indexSectionIdx + 1, 0, {
            ...newDupSection,
          });
        }
      }
    },

    insertDuplicateHtmlById: (state, action) => {
      const { dupId } = action.payload;

      // Find the section to duplicate
      const dupSection = state.pages[state.index]?.find(
        (section) => section._id === dupId
      );

      if (dupSection) {
        const generateRandomId = () => {
          const chars =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
          return Array.from(
            { length: 12 },
            () => chars[Math.floor(Math.random() * chars.length)]
          ).join("");
        };

        const newDupSection = { ...dupSection, _id: generateRandomId() };
        // Find the index of the section
        const dupSectionIdx = state.pages[state.index].indexOf(dupSection);

        // Insert a copy of the section immediately below its original position
        state.pages[state.index].splice(dupSectionIdx + 1, 0, {
          ...newDupSection,
        });
      }
    },

    addUserHtml: (state, action) => {
      if (state.pages[state.index]?.length > 2) {
        // Pop the last element
        const poppedElement = state.pages[state.index].pop();

        // Push the new payload into the array
        state.pages[state.index].push(action.payload);

        const snapshot = {
          data: state.data,
          pages: state.pages,
          index: state.index,
          isSiteCreated: state.isSiteCreated,
        };
        console.log("SnappyA ", snapshot);
        state.history.push(snapshot);

        // Push the popped element back into the array
        state.pages[state.index].push(poppedElement);
      } else {
        state.pages[state.index].push(action.payload);

        const snapshot = {
          data: state.data,
          pages: state.pages,
          index: state.index,
          isSiteCreated: state.isSiteCreated,
        };
        console.log("SnappyA ", snapshot);
        state.history.push(snapshot);
      }
    },
    reflectSection: (state, action) => {
      const { title } = action.payload;
      const sectionType = title === "Navigation" ? 0 : 9;
      const currentPage = state.pages[state.index];

      console.log("In reflector  = > ", title, currentPage, sectionType);

      // Find the section with the given sectionType
      const section = currentPage.find((s) => s.sectionType === sectionType);

      console.log("Section ", section);

      if (section) {
        if (sectionType === 0) {
          // Handle the navigationHeader (sectionType = 0)
          const htmlTemplate = section.htmlTemplate;
          console.log("In Reflecter", htmlTemplate);

          // Call copyNavLinksToMobile to update the mobile nav container
          const newHtmlTemplate = copyNavLinksToMobile(htmlTemplate);
          section.htmlTemplate = newHtmlTemplate;

          // Iterate through all pages using Object.keys() to check if any page contains the section with sectionType = 0
          const allPages = Object.keys(state.pages);
          for (let pageIndex of allPages) {
            const page = state.pages[pageIndex];
            const sectionIndex = page.findIndex((s) => s.sectionType === 0);

            if (sectionIndex !== -1) {
              // Replace the section with sectionType = 0
              page[sectionIndex] = section;
            } else {
              // If no section with sectionType = 0, insert it at the front
              page.unshift(section);
            }
          }
        } else if (sectionType === 9) {
          // Handle the footer (sectionType = 9)
          const allPages = Object.keys(state.pages);
          for (let pageIndex of allPages) {
            const page = state.pages[pageIndex];
            const sectionIndex = page.findIndex((s) => s.sectionType === 9);

            if (sectionIndex !== -1) {
              // Replace the section with sectionType = 9 (footer)
              page[sectionIndex] = section;
            } else {
              // If no section with sectionType = 9, add the footer at the end
              page.push(section);
            }
          }
        }
      } else {
        // If no section with the given sectionType, insert the section at the front of the array
        currentPage.unshift(action.payload);
      }
    },

    insertSectionWithLine: (state, action) => {
      if (state.pages[state.index]?.length > 2) {
        const updatedScript = [];
        const updatedArray = [];
        const sectionSeps = [];
        for (let i = 0; i < state.pages[state.index].length; i++) {
          updatedArray.push(state.pages[state.index][i]);
          if (i < state.pages[state.index].length) {
            const _id = generateUUID();
            sectionSeps.push(_id);
            updatedArray.push({
              htmlTemplate: ` <section class="flex items-center justify-center w-full my-4 mx-10">
                <div class="flex-1 border-t border-gray-200"></div>
                  <div  name="dup_section_inserter_input_${_id}" class="hidden items-center mx-5 gap-1">
                <input 
                  name="input_${_id}"
                  type="text" 
                  placeholder="Section Id" 
                  class="w-[200px] h-8 px-3 py-2 border border-gray-300 rounded-full focus:outline-none "
                />
                <button 
                  name="button_addition_${_id}"
                  class="w-8 h-8 rounded-full flex items-center justify-center bg-black text-white  hover:bg-gray-800">
                 <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#ffffff" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus"><path d="M5 12h14"/><path d="M12 5v14"/></svg>
                </button>
                <button 
                 name="button_cancel_${_id}"
                  class="w-8 h-8 rounded-full flex items-center justify-center bg-black text-white  hover:bg-gray-800">
                 <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#ffffff" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
                </button>
              </div>

               <button 
                  name="button_${_id}"
                  class="w-8 h-8 rounded-full mx-5 flex items-center justify-center bg-black text-white  hover:bg-gray-800">
                 <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="#ffffff" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus"><path d="M5 12h14"/><path d="M12 5v14"/></svg>
                </button>
               
                <div class="flex-1 border-t border-gray-300"></div>


              
              </section>
              `,

              sectionType: 99, // Line section type
              _id: _id,
            });

            updatedScript.push(`
                  <script>
            document.addEventListener("DOMContentLoaded", function () {
              const buttons = document.getElementsByName("button_${_id}"); // Returns a NodeList
              const button_cancel = document.getElementsByName("button_cancel_${_id}"); // Returns a NodeList
                const button_addition = document.getElementsByName("button_addition_${_id}"); // Returns a NodeList
              
              if (buttons.length > 0) { // Check if any elements are found
                const button = buttons[0]; // Access the first element in the NodeList

                button.addEventListener("click", function () {
                 
                  const input = document.getElementsByName("dup_section_inserter_input_${_id}")[0]; // Access the input element

                  if (button) button.style.display = "none"; // Hide the button
                  if (input) {
                    input.style.display = "flex"; // Show the input field
                  }
                });
              }

              if (button_cancel.length > 0) { // Check if any elements are found
                const button = button_cancel[0]; // Access the first element in the NodeList

                button.addEventListener("click", function () {
                 
                  const input = document.getElementsByName("dup_section_inserter_input_${_id}")[0]; // Access the input element
                  const buttons_ = document.getElementsByName("button_${_id}"); // Returns a NodeList
                  if (buttons_.length > 0) { // Check if any elements are found
                   const button2 = buttons_[0]; // Access the first element in the NodeList

                button2.removeAttribute("style")}
                  if (input) {
                    input.style.display = "none"; // Show the input field
                  }
                });
              }
            });           
          </script>
          <script>
           document.addEventListener("DOMContentLoaded", () => {
            // Assuming sectionSeparatorIds is available globally or defined elsewhere
          
              const inputName = "input_${_id}";
              const input = document.getElementsByName(inputName)[0]; // Get the input by name

              if (input) {
                // Find the button with a dynamic name
                const button = document.getElementsByName("button_addition_${_id}")[0]; 

                // Add the button click event listener
                if (button) {
                  button.addEventListener("click", () => {
                    // Store the input value in localStorage
                    const inputValue = input.value;
                    localStorage.setItem("duplicate_section_id", inputValue);

                    // Send a message to the parent (React) window to call the React function
                    window.parent.postMessage(
                      { action: "callDupdupdup4455", value: {"inputValue" : inputValue, "index": "${_id}"} },
                      "*" // Replace "*" with a specific origin if security is required
                    );
                  });
                } else {
                  console.warn("Button with name button_addition_${_id} not found");
                }
              }
         
          });</script>
          `);
          }
        }

        state.script = updatedScript;
        console.log("Script = > ", updatedScript);
        state.pages[state.index] = updatedArray;
        state.sectionSeparators = sectionSeps;
      }
    },

    removeLineSections: (state) => {
      if (state.pages[state.index]?.length > 0) {
        // Filter out all elements with sectionType: 99
        state.pages[state.index] = state.pages[state.index].filter(
          (section) => section.sectionType !== 99
        );
      }
    },

    updateUserHtml: (state, action) => {
      const { _id, newHtmlTemplate } = action.payload;
      // console.log("Payload id = ", _id);
      // console.log("PayLoad = ", action.payload);

      const elementIndex = state.pages[state.index].findIndex(
        (element) => element?._id === _id
      );
      let sectionType = state.pages[state.index][elementIndex].sectionType;

      if (sectionType === 0 || sectionType === 9) {
        const allPages = Object.keys(state.pages);
        console.log("All Pages : ", allPages);
        allPages.forEach(function (page, index) {
          let elementIndex_ = state.pages[page].findIndex(
            (element) => element?.sectionType === sectionType
          );
          if (state.pages[page][elementIndex_]?.htmlTemplate) {
            state.pages[page][elementIndex_].htmlTemplate =
              copyNavLinksToMobile(newHtmlTemplate);
          }
        });
      } else {
        if (elementIndex !== -1) {
          state.pages[state.index][elementIndex].htmlTemplate = newHtmlTemplate;
          // console.log(state.pages[state.index][elementIndex].htmlTemplate);
        }
      }
      const snapshot = {
        data: state.data,
        pages: state.pages,
        index: state.index,
        isSiteCreated: state.isSiteCreated,
      };
      console.log("Snappy ", snapshot);
      state.history.push(snapshot);
    },

    deleteUserHtml: (state, action) => {
      // console.log(" Deleting Payload = ", action.payload);
      const elementIndex = state.pages[state.index].findIndex(
        (element) => element._id === action.payload
      );
      console.log("Deleting Index", elementIndex);
      if (elementIndex !== -1) {
        state.pages[state.index].splice(elementIndex, 1); // Remove the element from the array
        console.log("Deleting Element deleted");
      }
    },

    reorderTasks: (state, action) => {
      const payload = action.payload;
      const newPos = payload.newPos;
      const originalPos = payload.originalPos;
      console.log("inside reducer : ", payload);
    },

    fetchUserHtml: (state) => {
      return state.pages[state.index];
    },
    emptyUserHtml: (state) => {
      state.pages = null;
      state.index = "Home";
      state.isSiteCreated = 0;
    },
  },
});

// Export the reducer and actions
export default userHtmlSlice.reducer;
export const {
  setUserHtml,
  setPageIndex,
  deletePage,
  setHistory,
  savePageState,
  setPageHtmlState,
  reorderTasks,
  initalizeUserSlice,
  addToHistory,
  duplicateRenamePage,
  undo,
  deleteUserHtml,
  setIsSiteCreated,
  addSectionToPage,
  setPages,
  addPage,
  swapSectionWithLowerSection,
  swapSectionWithUpperSection,
  insertSectionWithLine,
  emptyUserHtml,
  fetchUserHtml,
  addUserHtml,
  updatePlaceholders,
  updateAnchorUrls,
  updateColors,
  updateImageUrls,
  updateUserHtml,
  reflectSection,
  removeLineSections,
  insertDuplicateHtmlById,
  insertDuplicateHtml,
} = userHtmlSlice.actions;
