import React, { useEffect, useState } from "react";
import "../../SignIn/spinner.css";
import { useDispatch, useSelector } from "react-redux";
import { addSectionToPage } from "../../../Store/Slices/userHtmlSlice";
import supabase from "../../../config/supabse";

function AddSectionProgress(props) {
  const dispatch = useDispatch();
  const Idea = useSelector((state) => {
    return state.Idea.data;
  });
  const Name = useSelector((state) => {
    return state.Name.data;
  });
  const subdomainId = useSelector((state) => {
    return state.Name.subdomain;
  });
  const Color = useSelector((state) => {
    return state.Color.data;
  });
  const Fonts = useSelector((state) => {
    return state.Color.fonts;
  });
  const Title = useSelector((state) => {
    return state.Color.title;
  });
  const Description = useSelector((state) => {
    return state.Color.description;
  });
  const Section = useSelector((state) => {
    return state.Section.data;
  });
  const Logo = useSelector((state) => {
    return state.Logo.data;
  });
  const siteId = useSelector((state) => state.Color.siteId);
  const Hue = useSelector((state) => {
    return state.Color.hue;
  });
  const Data = useSelector((state) => state.userHtml.data);
  const Pages = useSelector((state) => state.userHtml.pages);
  const index = useSelector((state) => state.userHtml.index);
  // const history = useSelector((state) => state.userHtml.history);

  const [usedImage, setUsedImages] = useState([]);
  const [images, setImages] = useState([]);
  const [queries, setQueries] = useState([]);

  const page_index = useSelector((state) => state.userHtml.index);
  const isSiteCreated = useSelector((state) => state.userHtml.isSiteCreated);

  const replaceImagesInHTML = (htmlString, images) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const imgElements = doc.querySelectorAll(
      'img:not([name="logo-image"]):not([name="person-image"]):not([name="static-image"])'
    );
    console.log("404: , ", images);
    imgElements.forEach((img, index) => {
      const index_ = parseInt(Math.floor(Math.random() * images.length));

      const imageUrl = images[index_];
      img.setAttribute("src", imageUrl);
    });
    return doc.body.innerHTML;
  };

  useEffect(() => {
    const fetchUserAssets = async () => {
      try {
        const { data, error } = await supabase
          .from("histories")
          .select("userAssets")
          .eq("domain", subdomainId)
          .eq("deployedVersion", true);

        if (error) {
          console.error("Code19 Error fetching userAssets:", error);
          return;
        }

        console.log("Code19 : ", data);
        setImages(data[0].userAssets?.images || []);
        setUsedImages(data[0].userAssets?.used || []);
        setQueries(data[0].userAssets?.queries || []);
        insertSection(data[0].userAssets?.images);
      } catch (error) {
        console.error("Error fetching userAssets:", error);
      }
    };

    const insertSection = async (images) => {
      try {
        const postData = {
          idea: Idea,
          name: Name,
          color: Color,
          siteId: siteId,
          fonts: Fonts,
          sectionArray: Section,
        };

        console.log("Code:900 Data to be Sent : ", postData);
        const apiEndpoint =
          process.env.REACT_APP_API_ENDPOINT || "http://localhost:8080";
        const apiUrl = `${apiEndpoint}/helloworld/generative/body`;

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        });

        const data = await response.json();

        if (isSiteCreated) {
          console.log("Code:800 Section Insertion ", data.sections);
          const sections = data.sections;

          sections.forEach((section, index) => {
            if (section.sectionType !== 0) {
              const modifiedSection = {
                ...section,
                htmlTemplate: replaceImagesInHTML(
                  section.htmlTemplate
                    .replace(/@sitename/g, `${Name}`)
                    .replace(/@logoimage/g, `${Logo}`),
                  images
                ),
              };

              console.log(" Code:800 page_index : ", page_index);
              console.log(" Code:800 modifiedSection : -", modifiedSection);
              dispatch(
                addSectionToPage({
                  pageName: `${page_index}`,
                  section: modifiedSection,
                })
              );
              props.isInsertionComplete(0);
            }
          });
        }
      } catch (error) {
        console.error("Error during section insertion:", error);
      }
    };

    const handleAsyncOperations = async () => {
      if (subdomainId) {
        fetchUserAssets();
      }
    };

    handleAsyncOperations();
  }, [subdomainId]); // Add dependencies if needed

  return (
    <div className="flex flex-row gap-4 items-center justify-center">
      <p className="text-lg text-black">Adding</p>
      <div className="spinner"></div>
    </div>
  );
}

export default AddSectionProgress;
