import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../Builder_prompt_components/global_theme/fonts.css";

function LogOut(props) {
  const navigate = useNavigate();
  const { credId } = useParams();

  return (
    <>
      <button
        onClick={() => {
          navigate(`/dashboard/${credId}/`);
        }}
        className="flex items-center gap-2 w-full rounded-lg px-3 py-2 text-zinc-500  group transition-all hover:text-black group justify-center"
      >
        <ArrowLeftIcon className="w-6 h-6 mr-1 text-zinc-500  group-hover:text-black text-xs transition-colors" />
        Back to Dashboard
      </button>
    </>
  );
}

export default LogOut;

function ArrowLeftIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m12 19-7-7 7-7" />
      <path d="M19 12H5" />
    </svg>
  );
}
