import React from "react";
import "../../Builder_prompt_components/global_theme/fonts.css";

function WaitList() {


  return (
   <></>
    
  );
}

export default WaitList;
