import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "../../SignIn/spinner.css";
import supabase from "../../../config/supabse";
import pageHtmlforSaving from "../../Saving/SavingHtml";

function TakingYouToEditor({ trigger }) {
  const { credId } = useParams();
  const [isPublishing, setIsPublishing] = useState(true);
  const navigate = useNavigate();
  const Idea = useSelector((state) => {
    return state.Idea.data;
  });
  const Name = useSelector((state) => {
    return state.Name.data;
  });
  const subdomainId = useSelector((state) => {
    return state.Name.subdomain;
  });
  const Color = useSelector((state) => {
    return state.Color.data;
  });
  const Hue = useSelector((state) => {
    return state.Color.hue;
  });
  const siteId = useSelector((state) => {
    return state.Color.siteId;
  });
  const Fonts = useSelector((state) => {
    return state.Color.fonts;
  });
  const Section = useSelector((state) => {
    return state.Section.data;
  });
  const Data = useSelector((state) => state.userHtml.data);
  const Pages = useSelector((state) => state.userHtml.pages);
  const index = useSelector((state) => state.userHtml.index);
  const isSiteCreated = useSelector((state) => state.userHtml.isSiteCreated);
  // const history = useSelector((state) => state.userHtml.history);
  const Queries = useSelector((state) => state.Query.data);
  const Images = useSelector((state) => state.Query.images);
  const ImagesUsed = useSelector((state) => state.Query.used);

  async function saveData() {
    const { data: mostRecentRow, error: fetchError } = await supabase
      .from("histories")
      .select("*")
      .eq("domain", subdomainId)
      .eq("deployedVersion", true)
      .order("created_at", { ascending: false })
      .limit(1);

    if (fetchError) {
      console.error("Error fetching the most recent row:", fetchError);
    } else if (mostRecentRow.length > 0) {
      const { error: upsertError } = await supabase.from("histories").upsert({
        ...mostRecentRow[0],
        deployedVersion: false,
      });

      if (upsertError) {
        console.error("Error upserting deployedVersion:", upsertError);
      }

      const { data, error } = await supabase.from("histories").insert({
        webIdea: Idea,
        webName: Name,
        webColor: Color,
        userData: {
          data: Data,
          pages: Pages,
          index: index,
          isSiteCreated: isSiteCreated,
        },
        userAssets: {
          images: Images,
          queries: Queries,
          used: ImagesUsed,
        },
        userId: credId,
        webSectionType: Section,
        webTheme: {
          hue: Hue,
          fonts: Fonts,
        },
        siteId: siteId,
        domain: subdomainId,
        deployedVersion: true, // Set deployedVersion to true for the new row
      });

      if (error) {
        const { error: upsertErrorRollback } = await supabase
          .from("histories")
          .upsert({
            ...mostRecentRow[0],
            deployedVersion: true,
          });
        console.error("Error inserting data:", error);
      }
    }
  }

  const pages_ = useSelector((state) => state.userHtml.pages);
  const domain_ = useSelector((state) => state.Name.subdomain);
  console.log("Pages : ", pages_);
  console.log("Domain : ", domain_);

  async function upsertWebsite(subdomain) {
    const { data, error } = await supabase
      .from("websites")
      .upsert([{ domain: subdomain, lastUpdate: new Date() }], {
        onConflict: ["domain"],
        returning: "minimal",
      });

    if (error) {
      console.error("Error upserting website:", error.message);
      return null;
    }

    console.log("Upsert successful for subdomain:", subdomain);
    return data;
  }

  async function insertWebsite(pages, domain) {
    setIsPublishing(true);
    const folderPath = `${domain}/`;
    const updatedWebsite = parsePages(pages);

    try {
      // First, list all files in the folder
      const { data: files, error: listError } = await supabase.storage
        .from("hello-world")
        .list(folderPath, { limit: 1000 }); // Adjust limit if necessary

      if (listError) {
        console.error("Error listing files:", listError.message);
        throw listError; // Exit early if there’s an error listing files
      }

      // Delete all files in the folder
      const deletePromises = files.map((file) =>
        supabase.storage
          .from("hello-world")
          .remove([`${folderPath}${file.name}`])
      );

      const deleteResults = await Promise.all(deletePromises);

      deleteResults.forEach(({ error, data }, index) => {
        if (error) {
          console.error(`Error deleting ${files[index].name}:`, error.message);
        } else {
          console.log(`Deleted ${files[index].name} successfully.`);
        }
      });

      // Upload new files
      const uploadPromises = Object.entries(updatedWebsite).map(
        ([key, htmlCode]) => {
          const fileName = `${key.toLowerCase()}.html`;
          return supabase.storage
            .from("hello-world")
            .upload(
              `${folderPath}${fileName}`,
              new Blob([htmlCode], { type: "text/html" })
            )
            .then(({ data, error }) => {
              if (error) {
                console.error(`Error uploading ${fileName}:`, error.message);
              } else {
                console.log(`Uploaded ${fileName} successfully.`);
              }
            });
        }
      );

      await upsertWebsite(domain);
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error("Error handling files:", error);
    }

    setIsPublishing(false);
  }

  function mergeHtmlTemplates(page) {
    let mergedHtml = "";
    page.forEach((section) => {
      mergedHtml += section.htmlTemplate;
    });
    return mergedHtml;
  }

  function parsePages(pages) {
    const parsedPages = {};
    for (const pageName in pages) {
      if (pages.hasOwnProperty(pageName)) {
        const sections = pages[pageName];
        const mergedHtml = mergeHtmlTemplates(sections);
        const finalHtml = pageHtmlforSaving(subdomainId, mergedHtml);
        parsedPages[pageName] = finalHtml.replace(/#33dollar/g, "$");
      }
    }

    return parsedPages;
  }

  async function insertUserMessage() {
    try {
      const { data, error } = await supabase.from("userMessages").insert([
        {
          messageBody: {
            "first-name": "Helloworld",
            "last-name": "Organization",
            email: "support@Helloworld.to",
            phone: "-",
            message: "Hello! from Helloworld",
          },
          siteId: subdomainId,
        },
      ]);

      if (error) {
        throw error;
      }

      console.log("Message inserted successfully:", data);
      return data;
    } catch (error) {
      console.error("Error inserting message:", error);
      throw error; // Optionally rethrow error for further handling
    }
  }

  const dispatch = useDispatch();
  var html = "";
  var webPage = [];
  function manipulate_section_string(_id, htmlTemplate) {
    var htmlStr = htmlTemplate;

    if (htmlStr.startsWith('"') && htmlStr.endsWith('"')) {
      htmlStr = htmlStr.slice(1, -1);
    }
    if (htmlStr.startsWith("'") && htmlStr.endsWith("'")) {
      htmlStr = htmlStr.slice(1, -1);
    }
    webPage.push(htmlStr);
  }

  useEffect(() => {
    const initiate = async () => {
      await insertWebsite(pages_, subdomainId);
      await saveData();
      await insertUserMessage();

      navigate(`/main/${credId}`);
    };
    initiate();
  }, []);

  return (
    <>
      <div className="flex w-full h-[100vh] overflow-hidden flex-row items-center justify-center gap-4">
        <h1 className="text-2xl font-semibold text-black">
          Taking You To The Editor
        </h1>
        <div className="spinner"></div>
      </div>
    </>
  );
}

export default TakingYouToEditor;

//
