import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setSection,
  setSectionEmpty,
} from "../../../Store/Slices/SectionSlice";
import "../../MainPage/Mainpage.css";
import "../../MainPage/customsb.css";
import Progress from "../Progress/Progress";
import "../global_theme/fonts.css";
import "../../SignIn/spinner.css";
import AddSectionProgress from "./AddSectionProgress";
import { IoCloseCircleOutline } from "react-icons/io5";
function SectionSelector(props) {
  const dispatch = useDispatch();
  const [sectionInsertion, setSectionInsertion] = useState(0);
  const [navbar, setNavbar] = useState(false);
  const [hero, setHero] = useState(false);
  const [services, setServices] = useState(false);
  const [tabs, setTabs] = useState(false);
  const [testimonial, setTestimonial] = useState(false);
  const [about, setAbout] = useState(false);
  const [blog, setBlog] = useState(false);
  const [banner, setBanner] = useState(false);
  const [faqs, setFaqs] = useState(false);
  const [contact, setContact] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [content, setContent] = useState(false);
  const [footer, setFooter] = useState(false);
  const [productCatelog, setProductCatalog] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [blogCard, setBlogCard] = useState(false);
  const [logoCloud, setLogoCloud] = useState(false);
  const [heroV2, setHeroV2] = useState(false);

  const Sections_Dispatch = () => {
    if (navbar === true) {
      dispatch(setSection(0));
    }
    if (hero === true) {
      dispatch(setSection(1));
    }
    if (tabs === true) {
      dispatch(setSection(120));
    }
    if (heroV2 === true) {
      dispatch(setSection(101));
    }
    if (services === true) {
      dispatch(setSection(2));
    }
    if (testimonial === true) {
      dispatch(setSection(3));
    }
    if (about === true) {
      dispatch(setSection(4));
    }
    if (blog === true) {
      dispatch(setSection(5));
    }
    if (banner === true) {
      dispatch(setSection(6));
    }
    if (contact === true) {
      dispatch(setSection(7));
    }
    if (faqs === true) {
      dispatch(setSection(8));
    }
    if (footer === true) {
      dispatch(setSection(9));
    }
    if (newsletter === true) {
      dispatch(setSection(11));
    }
    if (content === true) {
      dispatch(setSection(12));
    }
    if (productCatelog === true) {
      dispatch(setSection(13));
    }

    if (gallery === true) {
      dispatch(setSection(15));
    }
    if (blogCard === true) {
      dispatch(setSection(16));
    }
    if (logoCloud === true) {
      dispatch(setSection(14));
    }
    if (faqs === true) {
      dispatch(setSection(17));
    }
  };

  function setInsertionCopmlete(val) {
    setSectionInsertion(val);
    props.isVisible(0);
  }

  const handleClick = () => {
    dispatch(setSectionEmpty());
    Sections_Dispatch();
    console.log("Code:900 All set");
    setSectionInsertion(1);
  };

  useEffect(() => {
    if (
      hero ||
      navbar ||
      newsletter ||
      about ||
      services ||
      gallery ||
      contact ||
      blog ||
      productCatelog ||
      testimonial ||
      content ||
      faqs ||
      footer ||
      blogCard ||
      logoCloud ||
      banner ||
      heroV2 ||
      tabs
    ) {
      handleClick();
    }
  }, [
    hero,
    navbar,
    about,
    content,
    services,
    blog,
    productCatelog,
    gallery,
    contact,
    testimonial,
    heroV2,
    faqs,
    newsletter,
    blogCard,
    logoCloud,
    footer,
    banner,
    tabs,
    handleClick,
  ]);

  return (
    <>
      {sectionInsertion === 1 ? (
        <div className="border-solid border-2 rounded-md mt-4 border-gray-300 py-3 w-[95%]">
          <AddSectionProgress isInsertionComplete={setInsertionCopmlete} />
          {/* <Progress val={sectionInsertion} isInsertionCopmlete={setInsertionCopmlete} /> */}
        </div>
      ) : (
        <div className="rounded-md mt-4  border-[#606060] py-3 w-[100%]">
          <div className="flex flex-row justify-between px-2">
            <p className="text-black font_inter text-md text-center ">
              Select a Section
            </p>
            <button
              onClick={() => {
                props.isVisible(0);
              }}
              className="text-2xl hover:text-white font_inter text-gray-300"
            >
              {" "}
              <IoCloseCircleOutline className="text-2xl text-zinc-500 hover:text-white" />{" "}
            </button>
          </div>

          <div className="flex flex-col   ">
            <div className="flex-col flex max-h-[35vh]  overflow-auto custom-scrollbar   justify-start items-start text-gray-300 mt-4 px-2">
              <div
                onClick={() => {
                  setTabs(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Tabs</h3>
                <p className="text-[#A2A2A2] font_inter text-xs">
                  Set the links in the tabs and redirect user to different
                  pages.
                </p>
              </div>
              <div
                onClick={() => {
                  setHero(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Header</h3>
                <p className="text-[#A2A2A2] font_inter text-xs">
                  Capture immediate attention with a stylish header, featuring
                  key visuals and a quick menu overview.
                </p>
              </div>

              <div
                onClick={() => {
                  setHeroV2(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Hero {"(New)"}</h3>
                <p className="text-[#A2A2A2] font_inter text-xs">
                  Capture immediate attention with a stylish hero, featuring key
                  visuals and a quick menu overview in the latest version.
                </p>
              </div>

              <div
                onClick={() => {
                  setAbout(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">About</h3>
                <p className="text-[#A2A2A2] font_inter text-xs">
                  Personalize your brand by sharing your story, mission, and the
                  individuals driving your passion.
                </p>
              </div>
              <div
                onClick={() => {
                  setServices(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec]transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Features</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Showcase what makes your offerings unique, highlight standout
                  features and key functionalities.
                </p>
              </div>

              <div
                onClick={() => {
                  setTestimonial(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Testimonial</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Your customer or client reviews about the service or product
                  provided by you.
                </p>
              </div>
              <div
                onClick={() => {
                  setBanner(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Banner</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Showcase the statistics of your website/compnay
                </p>
              </div>
              <div
                onClick={() => {
                  setContact(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Contact</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Provide the contact information e.g. location, phone, email
                </p>
              </div>

              <div
                onClick={() => {
                  setContent(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Content</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Provide the content for the website e.g. specific information
                  or blog
                </p>
              </div>
              <div
                onClick={() => {
                  setNewsletter(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Newsletter Form</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  A newsletter subscription form for your customer or client.
                </p>
              </div>

              <div
                onClick={() => {
                  setBlog(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Blog</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Write a blog about a specific topic or your service
                </p>
              </div>

              <div
                onClick={() => {
                  setBlogCard(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Blog Cards</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Summarized entries with links to detailed blog posts.
                </p>
              </div>

              <div
                onClick={() => {
                  setProductCatalog(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Product Catalog</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Make a product or service catalog to showcase your SKU.
                </p>
              </div>

              <div
                onClick={() => {
                  setLogoCloud(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Logo Cloud</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Display of various company or brand logos visually arranged.
                </p>
              </div>

              <div
                onClick={() => {
                  setGallery(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Gallery</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Collection of images or artwork for visual browsing
                </p>
              </div>

              <div
                onClick={() => {
                  setFaqs(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Faqs</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Answer the Frequently Askeed Questions by Users.
                </p>
              </div>

              <div
                onClick={() => {
                  setFooter(true);
                }}
                className="bg-[#f5f5f5] p-4 rounded-lg mt-2 hover:bg-[#ececec] transition-colors duration-200 cursor-pointer w-full"
              >
                <h3 className="text-black font_inter">Footer</h3>
                <p className="text-[#A2A2A2] text-xs font_inter">
                  Ending footer for the website with navigation links.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SectionSelector;
