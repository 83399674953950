import React from "react";
import "../../Builder_prompt_components/global_theme/fonts.css";
import EmailLogin from "../../SignIn/EmailLogin";
import "../css/animation.css";

function Hero() {
  return (
    <>
      <div
        className="w-[99vw] min-h-[60vh] overflow-hidden relative h-auto flex justify-center items-center p-5 xl:p-10"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="absolute z-20 flex flex-col items-center justify-center gap-4 mt-20">
          <h1 className="text-4xl sm:text-5xl md:max-w-xl mx-auto md:text-[3.313rem] text-center font-[500] text-gray-900 leading-tight mb-4 tracking-[-1px]">
            Welcome to the world&apos;s easiest website builder
          </h1>
          <p className="text-[1.1rem] text-center md:max-w-lg text-gray-700 font-[500] mb-8 max-w-2xl mx-auto">
            Speed up the whole process of website creation, from information
            structure, copywriting to customizing and publishing your website.
          </p>
          <div className="flex  mt-5 items-center max-w-[80%] mx-auto justify-center w-full animate-slide-up-delay-3">
            <EmailLogin ishome={1} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
