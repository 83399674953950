import { useEffect, useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import { Column } from "./components/Column/Column";
import { savePageState } from "../../Store/Slices/userHtmlSlice";
import { useDispatch, useSelector } from "react-redux";
export default function DND(props) {
  const page_index = useSelector((state) => state.userHtml.index);
  let sections = props?.tasks;

  function sectionName(type) {
    if (type === 0) return "Navigation";
    else if (type === 1) return "Header";
    else if (type === 2) return "Features";
    else if (type === 3) return "Testimonial";
    else if (type === 4) return "About";
    else if (type === 5) return "Blog";
    else if (type === 6) return "Banner";
    else if (type === 7) return "Contact";
    else if (type === 8) return "Blog";
    else if (type === 9) return "Footer";
    else if (type === 10) return "Header Page";
    else if (type === 11) return "News Letter";
    else if (type === 12) return "Content";
    else if (type === 13) return "Product Catalog";
    else if (type === 14) return "Logo Cloud";
    else if (type === 15) return "Gallery";
    else if (type === 16) return "Blog Cards";
    else if (type === 17) return "Faqs";
    else if (type === 120) return "Tabs";
    else if (type === 101) return "Hero (new)";
  }

  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([
    { id: 1, title: "1" },
    { id: 2, title: "2" },
    { id: 3, title: "3" },
    { id: 4, title: "4" },
  ]);

  useEffect(() => {
    sections = sections?.map((item, index) => ({
      ...item,
      id: index + 1,
      title: sectionName(item.sectionType),
      _id: item._id,
    }));
    setTasks(sections);
  }, [props.data]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getTaskPos = (id) => tasks.findIndex((task) => task.id === id);

  const handleDragEnd = (event) => {
    console.log("The Event ", event);
    const { active, over } = event;
    if (active && over) {
      if (active?.id === over?.id || active?.id === 1) return;
      setTasks((tasks) => {
        const originalPos = getTaskPos(active.id);
        const newPos = getTaskPos(over.id);
        const newArr = arrayMove(tasks, originalPos, newPos);
        props.setData(newArr);
        dispatch(savePageState({ pageIndex: page_index, pageState: newArr }));

        return newArr;
      });
    }
  };

  return (
    <div className="App">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
      >
        {sections ? (
          <Column
            id="toDo"
            tasks={tasks}
            setData={props.setData}
            setTasks={setTasks}
            handleSaveState={props.handleSaveState}
          />
        ) : (
          <></>
        )}
      </DndContext>
    </div>
  );
}
