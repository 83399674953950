import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Builder_prompt_components/global_theme/fonts.css";
import "react-toastify/dist/ReactToastify.css";
import "../UI/inputField.css";
import supabase from "../../config/supabse";
import { useDispatch } from "react-redux";
import {
  setEmail_,
  setPlanDetails_,
  setPlanId_,
} from "../../Store/Slices/userDetailsSlice";

function EmailLogin(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    const getSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error retrieving session:", error.message);
        return;
      }
      if (data) {
        if (
          data &&
          data.session &&
          data.session.user.id &&
          data.session.access_token
        ) {
          let userId = data.session.user.id;
          let accessToken = data.session.access_token;
          let email = data.session.user.email;
          setId(userId);
          setToken(accessToken);

          dispatch(setEmail_(email));

          const { error: upsertError } = await supabase
            .from("profiles")
            .upsert(
              { email: email, userId: userId },
              { onConflict: ["email"] }
            );

          if (upsertError) {
            console.error(upsertError);
          } else {
            setAuth(true);
            console.log("Profile upserted successfully");
          }

          const { data: userPlanData, error: userPlanError } = await supabase
            .from("userPlans")
            .select("*")
            .eq("userId", userId);

          if (userPlanError) {
            console.error("Error checking user plan:", userPlanError.message);
          } else if (!userPlanData || userPlanData?.length === 0) {
            const { error: upsertError } = await supabase
              .from("userPlans")
              .upsert([{ userId: userId, planId: 1 }]);

            if (upsertError) {
              console.error("Error upserting user plan:", upsertError.message);
            } else {
              console.log("User plan upserted successfully.");
            }
          } else {
            console.log("User plan already exists:", userPlanData);
            fetchPlanById(userPlanData[0].planId);
          }
        }
      }
    };
    getSession();
  }, []);

  const fetchPlanById = async (planId) => {
    const { data, error } = await supabase
      .from("plans")
      .select("*")
      .eq("planId", planId);

    if (error) {
      console.error("Error fetching plan:", error.message);
    } else if (data) {
      console.log("THe Plan: ", data);
      dispatch(setPlanId_({ planId: planId }));
      dispatch(setPlanDetails_({ planDetails: data[0] }));
    }
  };

  return (
    <>
      {" "}
      {!auth ? (
        <div className="flex items-center justify-center w-full">
          <button
            onClick={() => {
              navigate(`/signin`);
            }}
            className="flex items-center justify-start w-auto h-auto px-6 py-2 text-white bg-black rounded-full text-md font_inter"
          >
            Login to Get Started {"-->"}
          </button>{" "}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-auto">
          <button
            onClick={() => {
              navigate(`/loading/${id}/${token}/1`);
            }}
            className="flex items-center justify-start w-auto h-auto px-6 py-2 text-white bg-black rounded-full text-md font_inter"
          >
            Start Building
          </button>{" "}
        </div>
      )}{" "}
    </>
  );
}

export default EmailLogin;
