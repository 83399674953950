import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./Task.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserHtml,
  insertDuplicateHtmlById,
  reflectSection,
  savePageState,
} from "../../../../Store/Slices/userHtmlSlice";
import { useEffect, useState } from "react";

export const Task = ({
  id,
  title,
  _id,
  setData,
  tasks,
  setTasks,
  handleSaveState,
}) => {
  const dispatch = useDispatch();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const page_index = useSelector((state) => state.userHtml.index);
  const deleteSection = (event) => {
    console.log("Clicked");
    const newTask = tasks.filter((task) => task._id !== _id);
    console.log("After Deletion : ", newTask);
    setTasks(newTask);
    setData(newTask); // Update tasks in the parent component
    dispatch(deleteUserHtml(_id)); // Dispatch the delete action
    dispatch(savePageState({ pageIndex: page_index, pageState: newTask }));
  };

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const [isCopied, setIsCopied] = useState(false);

  const duplicateSection = () => {
    dispatch(insertDuplicateHtmlById({ dupId: _id }));
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(_id);
      console.log("Text copied to clipboard");
    } catch (err) {
      console.log("Failed to copy text.");
    }
  };

  const [isSectionReflecting, setIsSectionReflecting] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      {title && (
        <div
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          className="task flex items-center justify-between w-full relative "
        >
          <MenuIcon className="w-6 h-6 text-[#a3a3a3]" />
          <button
            onClick={() => {
              copyToClipboard();
            }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {title}
          </button>

          {showTooltip && (
            <div className="absolute top-[-20px] left-[20%] transform -translate-x-1/2 bg-black text-white text-xs px-2 py-1  z-50 rounded shadow">
              {_id}
            </div>
          )}
          <div className="flex flex-row items-center justify-end ml-auto ">
            <button
              onClick={(_id) => {
                duplicateSection();
              }}
              className="w-4 h-4 z-[100]  text-[#a3a3a3] hover:text-black cursor-pointer transition-colors mr-1 flex items-center justify-center"
            >
              {!isCopied ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-copy"
                >
                  <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
                  <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-check"
                >
                  <path d="M20 6 9 17l-5-5" />
                </svg>
              )}
            </button>

            <button
              onClick={() => {
                deleteSection();
              }}
              className="w-4 h-4 z-[100]  text-[#a3a3a3] hover:text-white cursor-pointer transition-colors mr-2 flex items-center justify-center"
            >
              <TrashIcon className="w-4 h-4 text-[#a3a3a3] hover:text-black cursor-pointer transition-colors" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

function TrashIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 6h18" />
      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
    </svg>
  );
}

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}
